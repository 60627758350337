.mg-title2{
    display:flex;
    text-transform: uppercase;
    width:calc(100% - 20px);
    padding:3px 10px 3px 0;
    font-family: avenir_heavy;
    background-color: #002854;
    color:#fff;
    padding:3px 10px;
}
.mg-charger-content{
    width:100%;
    display: flex;
    flex-flow: row wrap;
    gap:10px;
    padding:5px 0;
    margin-bottom:10px;
}
.mg-charger-box{
    display: flex;
    width:calc(25% - 8px);
    background-color: #e9e9e9;
    flex-direction: column;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mg-charger-box:hover{
    background-color: #c7e4f1;
}
.mg-charger-box-header{
    display:flex;
    justify-content: center;
    width:100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #006bb8;
    color: #fff;
    font-family: avenir_heavy;
    padding:5px 0;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.mg-charger-box-body{
    display:flex;
    justify-content: center;
    width:calc(100% - 10px);
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    align-items: center;
    padding:10px 5px;
    font-size:14px;
    line-height: normal;
}
.mg-charger-box-footer{
    display:flex;
    justify-content: center;
    width:calc(100% - 10px);
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    padding:0 5px 10px 5px;
    line-height: normal;
}
.mg-charger-status{
    display:flex;
    justify-content: center;
    width:calc(100% - 10px);
    background-color: #0ba026;
    color:#FFF;
    padding:5px 10px;
    font-size:14px;
    font-family: avenir_heavy;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
.mg-text{
    display:flex;
}
.mg-desc1{
    text-align:center;
    font-size:12px;
    margin-top:5px;
    font-family: avenir_heavy;
}
.status-available { background-color: green; }
.status-blocked { background-color: #4a4a4a; }
.status-charging { background-color: #ffd828;color:#000; }
.status-inoperative { background-color: #c42419; }
.status-outoforder { background-color: #c42419; }
.status-planned { background-color: #ffd828;color:#000; }
.status-removed { background-color: #c42419; }
.status-reserved { background-color: #ffd828;color:#000;}

.mg-charger-content-closing {
    animation: closeChargerContent 0.5s forwards;
}
@keyframes closeChargerContent {
    from { visibility: visible; margin-bottom: 10px; height: auto; }
    to { visibility: hidden; margin-bottom: 0; height: 0; }
}

@media (max-width: 1024px) {
    .mg-charger-box{
        width:calc(50% - 8px);
    }
}
@media (max-width: 768px) {
    .mg-charger-box{
        width:calc(50% - 8px);
    }
}