.mg-box{
    display:flex;
    flex-flow: row nowrap;
    gap:20px;
}
.mg-left-box,.mg-right-box{
    display:flex;
    width:50%;
    flex-direction: column;
    gap:20px;
}
.mg-general{
    display:flex;
    width:100%;
    align-items: flex-start;
    flex-direction: column;
}
.mg-orange{
    width: 80px;
    border-bottom: solid 5px #e13a00;
    margin: 10px 0;
}
.mg-chargers{
    display:flex;
    width:100%;
    align-items: flex-start;
    flex-direction: column;
}
.mg-desc{
    display:flex;
    width:100%;
    flex-direction: column;
}
.mg-photos{
    width:100%;
}
.photo{
    display: flex;
    height:100px;
    width:calc(25% - 10px);
    background-color: #d1d1d1;
}
.ddd{
    width:100%;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom:20px;
    gap: 20px;
}
.ddd-link,.ddd-text{
    width:50%;
    justify-content: center;
    align-self: center;
}
.ddd-link img{
    width:100%;
}
.mg-map{
    border:5px solid #E9E9E9;
    height:500px;
}
.mg-title{
    display:flex;
    text-transform: uppercase;
    width:100%;
    padding:3px 10px 3px 0;
    font-family: avenir_heavy;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .mg-box{
        flex-flow: column wrap;
    }
    .mg-left-box,.mg-right-box{
        width:100%;
    }
    .ddd-link,.ddd-text{
        width:100%;
        justify-content: center;
        align-self: center;
    }
    .ddd{
        flex-flow: column wrap;
    }
}