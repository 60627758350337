.banners{
    display: flex;
    width:100%;
    flex-direction:row;
    gap: 5px;
}
.banner-col{
    display: flex;
    width:calc(100% / 6);
    /*min-height:400px;*/
    background-color: #E9E9E9;
    flex-direction: column;
}
.banner-row1{
    display:flex;
    flex-direction: column;
    padding:10px;
    font-size: 18px;
    text-transform: uppercase;
    font-family:'avenir_heavy';
    text-align: center;
    line-height: normal;
    background-color: #002854;
    color:#fff;
    min-height:83px;
}
.banner-top-tilte{
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
}
.banner-info{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap:20px;
}
.banner-top{
    display: flex;
    flex-direction: column;
}
.banner-bottom{
    display: flex;
    flex-direction: column;
}
.banner-row2{
    display:flex;
    flex-direction: column;
    padding:0px 10px 0px 10px;
    color:#002854;
    text-align: center;
}
.banner-inner1{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.banner-inner2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-size: 20px;
    font-family:'avenir_black';
}
.banner-inner3{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 12px;
    line-height: 16px;
    min-height:32px;
}
.dot{
    display: flex;
    font-family: 'avenir_black';
    font-size: 40px;
    justify-content: center;
    color:#b4beca;
    line-height: 10px;
}
@media (max-width: 768px) {
    .banners{
        flex-direction: column;
    }
.banner-col{
    width:100%;
}
.banner-row1{
    min-height:0;
}
.banner-inner3{
    min-height:0;
}
}