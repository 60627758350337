.app-content{
    margin-top:30px;
    display:flex;
    width: 100%;
    gap:20px;
    flex-direction: row;
    height:100%;
}
.content-col1, .content-col2, .content-col3{
    width: calc(100% / 3);
}
.content-col3{
    border:5px solid #E9E9E9;
}
.content-col4{
    margin-bottom:30px;
}
.title-link{
    text-decoration: none;
    color:#002854;
    text-transform: uppercase;
    font-family: 'avenir_heavy';
    font-size: 14px;
    letter-spacing: 0.2px;
}
.title-link:hover{
    color:#006bb8;
}
.box-title-mg{
    display: flex;
    align-items: center;
    height:100%;
}
.title-logo {
    width:60px;
    height:60px;
    padding-right:10px;
    fill: #002854;
}
.title-logo-own {
    width:57px;
    height:57px;
    padding:5px 10px 5px 5px;
    fill: #002854;
}
.box-title-mg:hover .title-logo-own {
    fill: #006bb8;
}
@media (max-width: 768px) {
    .app-content{
        flex-direction:column;
    }
    .content-col1, .content-col2, .content-col3{
        width: 100%;
    }
}