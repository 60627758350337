@font-face {
	font-family:avenir_heavy;
	src:url('./fonts/avenirltstd-heavy-webfont.ttf');
	src:url('./fonts/avenirltstd-heavy-webfont.eot');
	src:url('./fonts/avenirltstd-heavy-webfont.woff');
	}
@font-face {
	font-family:avenir_roman;
	src:url('./fonts/avenirltstd-roman-webfont.ttf');
	src:url('./fonts/avenirltstd-roman-webfont.eot');
	src:url('./fonts/avenirltstd-roman-webfont.woff');
	}
	@font-face {
		font-family:avenir_black;
		src:url('./fonts/avenirltstd-black-webfont.ttf');
		src:url('./fonts/avenirltstd-black-webfont.eot');
		src:url('./fonts/avenirltstd-black-webfont.woff');
		}
:focus {
	outline:none;
	}
::-moz-focus-inner {
	border:0;
	}
html, body {
	height: 100%;
    margin: 0;
    font-family: 'avenir_roman';
    line-height: 25px;
    }
body{
    overflow-x: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
img{
	border:none;
    }